import Matomo from '@components/Matomo';

function CustomScripts() {
  return (
    <>
      <Matomo />
      <script
        async
        defer
        src='https://stable.loyjoy.com/widget.js?process=f6f8ccea-e9c7-4554-9324-a4bffa7ac728'
      />
    </>
  );
}

export default CustomScripts;
