import React, { Dispatch, SetStateAction } from 'react';
import CallWhite from '@images/call-white.svg';
import ChatWhite from '@images/chat-white.svg';
import MailWhite from '@images/mail-white.svg';
import LocationWhite from '@images/location-white.svg';
import TimeWhite from '@images/time-white.svg';
import Communicate from '@images/communicate-blue.svg';
import Energy from '@images/bulb-white.svg';
import Gas from '@images/gas-white.svg';
import Water from '@images/water-white.svg';
import Heat from '@images/heat-white.svg';
import clsx from 'clsx';
import Link from 'next/link';

type Props = {
  setNavOpen: Dispatch<SetStateAction<string>>;
  navOpen: string;
};

function ContactNav(props: Props) {
  const { navOpen, setNavOpen } = props;

  return (
    <>
      <button
        onClick={() => {
          if (navOpen === 'closed') setNavOpen('contactnavigation');
          if (navOpen === 'contactnavigation') setNavOpen('closed');
          if (navOpen === 'navigation') setNavOpen('contactnavigation');
        }}
        aria-label='Navigation umschalten'
        aria-controls='main-nav'
        className='contactnav__button'
      >
        <Communicate width='50px' height='50px' />
      </button>
      <nav
        className={clsx('contactnav', {
          'contactnav--open': navOpen === 'contactnavigation',
        })}
        id='contact-nav'
        aria-label='Kontaktnavigation'
      >
        <div className='contactnav__block'>
          <div className='contactnav__block--head callme'>
            {/* <Communicate width='73px' height='auto' /> */}
            <div>Kontakt</div>
          </div>
          <div className='contactnav__block--item'>
            <MailWhite width='2.2rem' height='2.2rem' />
            <div className='text'>
              <span>Email</span>
              <Link href='/online-services/kontakt/'>Kontaktformular</Link>
            </div>
          </div>
          <div className='contactnav__block--item'>
            <CallWhite width='2.2rem' height='2.2rem' />
            <div className='text'>
              <span>Hotline</span> <a href='tel:023029173600'>02302 9173-600</a>
            </div>
          </div>
          <div className='contactnav__block--item'>
            <ChatWhite width='2.2rem' height='2.2rem' />
            <div className='text'>
              <span>Whatsapp</span>
              <a href='https://wa.me/491636373173'>0163 6373173</a>
            </div>
          </div>
        </div>
        <div className='contactnav__block opening'>
          <div className='contactnav__block--head office'>
            {/* <Communicate width='73px' height='auto' /> */}
            <div>
              Kundenzentrum
              <br />
              Impuls
            </div>
          </div>
          <div className='contactnav__block--item'>
            <LocationWhite width='2.2rem' height='2.2rem' />
            <span>
              Hauptstr. 7
              <br /> 58452 Witten
            </span>
          </div>

          <div className='contactnav__block--item grid'>
            <TimeWhite width='2.2rem' height='2.2rem' />
            <span>
              <strong>Öffnungszeiten</strong>
              <br />
              Montag
              <br />
              08:00 - 13:00 Uhr
              <br /> 14:00 - 17:00 Uhr
            </span>
          </div>

          <div className='contactnav__block--item'>
            <div />
            <span>
              Dienstag bis Donnerstag
              <br />
              8:00 - 13.00 Uhr
              <br />
              14:00 - 16:00 Uhr
            </span>
          </div>
          <div className='contactnav__block--item'>
            <div />
            <span>
              Freitag
              <br />
              8:00 - 13:00 Uhr
            </span>
          </div>
          <div className='contactnav__block--item'>
            <div />
            <span>
              Samstag
              <br />
              8:00 - 12:00 Uhr
            </span>
          </div>
        </div>
        <div className='contactnav__block emergency'>
          <div className='contactnav__block--head emergency'>
            {/* <Communicate width='73px' height='auto' /> */}
            <div>Notdienst</div>
          </div>
          <div className='contactnav__block--item'>
            <Energy width='2.2rem' height='2.2rem' />
            <div className='text'>
              <span>Strom</span> <a href='tel:023029173651'>02302 9173-651</a>
            </div>
          </div>
          <div className='contactnav__block--item'>
            <Gas width='2.2rem' height='2.2rem' />
            <div className='text'>
              <span>Gas</span> <a href='tel:023029173652'>02302 9173-652</a>
            </div>
          </div>
          <div className='contactnav__block--item'>
            <Water width='2.2rem' height='2.2rem' />
            <div className='text'>
              <span>Wasser</span> <a href='tel:023029173653'>02302 9173-653</a>
            </div>
          </div>
          <div className='contactnav__block--item'>
            <Heat width='2.2rem' height='2.2rem' />
            <div className='text'>
              <span>Wärme</span> <a href='tel:023029173654'>02302 9173-654</a>
            </div>
          </div>
        </div>
        <div className='note'>
          <p>Die Störungsbeseitigung ist nach Dienstschluss unter Umständen kostenpflichtig.</p>
        </div>
      </nav>
    </>
  );
}

export default ContactNav;
