import React, { InputHTMLAttributes, ReactNode, useEffect } from 'react';
import Tooltip from '@components/Tooltip';
import InfoSvg from '@images/info.svg';
import Label from '../Label';

type Props = {
  label: ReactNode;
  infotext?: string;
  validation?: (value: string) => {};
  errorText?: string;
  submitted?: boolean;
  id: string;
  min_chars?: number;
  max_chars?: number;
  pattern?: string;
};

const Input = React.forwardRef<HTMLInputElement, Props & InputHTMLAttributes<HTMLInputElement>>(
  (props, ref) => {
    const {
      label,
      validation,
      errorText,
      submitted,
      infotext,
      min_chars,
      max_chars,
      pattern,
      ...rest
    } = props;

    const [isValid, setIsValid] = React.useState<boolean>(!errorText);
    const [validationText, setValidationText] = React.useState<string | undefined>(errorText);

    // This is a hack to work with the kontakt.tsx page because different validation after submit was asked for
    let showInitially = true;
    if (submitted !== undefined) {
      if (submitted === false) {
        showInitially = false;
      }
      if (submitted === true) {
        showInitially = true;
      }
    }

    const validate = (value: string) => {
      if (validation) {
        const validationValue = validation(value);

        if (typeof validationValue === 'string') {
          setValidationText(validationValue);
          setIsValid(!validationValue);
        } else if (typeof validationValue === 'boolean') {
          setIsValid(validationValue);
        } else {
          setValidationText(undefined);
          setIsValid(!!validationValue);
        }
      } else if (errorText) {
        setIsValid(true);
      }
    };

    useEffect(() => {
      setIsValid(!errorText);
      setValidationText(errorText);
      if (errorText) {
        const elem = document.getElementById(rest.id);
        elem?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }, [errorText, setIsValid, setValidationText, rest.id]);

    return (
      <div className='input'>
        <div className='label__container'>
          <Label htmlFor={rest.id} text={label} />

          {infotext && infotext.length > 0 ? (
            <Tooltip text={infotext}>
              <InfoSvg />
            </Tooltip>
          ) : null}
        </div>
        <input
          ref={ref}
          onChange={event => validate(event.target.value)}
          data-isvalid={!isValid ? isValid : null}
          {...rest}
          name={rest.id}
          className='input__element'
          {...(min_chars ? { minLength: min_chars } : {})}
          {...(max_chars ? { maxLength: max_chars } : {})}
          {...(pattern ? { pattern } : {})}
          {...(!label ? { 'data-no-label': true } : {})}
        />
        {!isValid && showInitially ? (
          <div className='invalid-input-text'>{validationText || 'Eingabe ist nicht valide'}</div>
        ) : null}
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
