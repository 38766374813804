import Link from 'next/link';
import { removeBaseUrl } from '@/utils/url';

import { WagtailPage } from '@/types';
import clsx from 'clsx';

type Button = {
  primary: boolean;
  button_text: string;
  button_page: WagtailPage | null;
  button_url: string | null;
};

type Props = {
  buttons: Button[];
  centered: boolean;
};

function Buttons(props: Props) {
  const { buttons, centered } = props;

  return (
    <div className={clsx('streamfield buttons', { 'buttons--center': centered })}>
      {buttons.map(button => (
        <Link
          key={button.button_text}
          href={
            button.button_page
              ? removeBaseUrl(button.button_page.full_url)
              : button.button_url ?? ''
          }
          passHref
          className={button.primary ? 'button button--primary' : 'button'}
          target={button.button_page ? '_self' : '_blank'}
        >
          {button.button_text}
        </Link>
      ))}
    </div>
  );
}

export default Buttons;
